import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetTransactionErrorsInput, TransactionErrorByIdQuery, TransactionErrorModel, Scalars } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_MESSAGING_LOG_BY_ID = gql`
    query transactionErrorById($input: GetTransactionErrorsInput!) {
        transactionErrors (input: $input) {
            nodes {
                id
                documentId
                description
                documentReferenceId
                messageType
                originalFileName
                purchaseOrderNumber
                processName
                rawMessage
                status
                createdTime
                client {
                    id
                    name
                }
                partner {
                    id
                    name
                }
                transaction {
                    id
                    name
                    direction
                    normalizedDocumentType
                }
            }
        }
    }
`;

export const queryErrorLogById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<TransactionErrorModel[] | undefined> => {
    const {data, errors} = await client.query<TransactionErrorByIdQuery>({
        variables: {
            input: {
                id: id
            }
        },
        query: QUERY_MESSAGING_LOG_BY_ID,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.transactionErrors?.nodes as TransactionErrorModel[];
    
};