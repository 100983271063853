import { useCallback, useEffect, useState } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled, Button, Grid, Link, Typography } from '@mui/material';
import { Viewer } from "../../util/Constants";
import { useTitle } from '../../util/Common';
import { clearState, selectError, selectErrorLog, captureUpdateLogStatus, selectUpdateLogStatus } from './ErrorLogDetailsSlice';
import { fetchErrorLogById, updateTransactionErrorStatus } from './ErrorLogDetailsActions';
import { setLocalStorageItem, viewerCanEdit } from '../../util/ViewerUtility';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, CardAvatar, TabContainer, TabHeader } from '../../util/SharedStyles';
import TabPanel from '../../components/TabPanel';
import DetailsPage from '../../components/DetailsPage';
import ErrorIcon from '@mui/icons-material/Error';
import { ExceptionStatus, RequestResult } from '../../gql-types.generated';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';

const TabContainerWithPadding = styled(TabContainer)(() => ({
    padding: '20px',
    overflowY: 'auto',
}));

const TypographyWithReturns = styled(Typography)((props) => ({
    whiteSpace: 'pre-line',
}));

interface ErrorLogDetailsProps {
    viewer: Viewer | undefined;
}

const ErrorLogDetails: React.FC<ErrorLogDetailsProps> = (props) => {
    // errorId will be passed in through router
    const { errorId } = useParams();
    const {viewer} = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [tabValue, setTabValue] = useState(0);
    const errorLog = useAppSelector(selectErrorLog);
    const error = useAppSelector(selectError);
    const updateStatus = useAppSelector(selectUpdateLogStatus);


    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    
    const canEdit = viewerCanEdit(viewer);

    const localStorageTabIndexName = "errorLogDetailsTabIndex_" + errorId;

    useEffect(() => {
        if (errorId) {
            dispatch(fetchErrorLogById(errorId));
        }
        return () => {
            dispatch(clearState());
        }
    },[]);

    useTitle(detailsPageTitle);
 

    const getErrorAV = useCallback(() => () => {
        if (errorLog?.status) {
            if (errorLog.status === ExceptionStatus.Error) {
                return (
                    <CardAvatar aria-label="error" alt="error">
                        <ErrorIcon />
                    </CardAvatar>
                );
            }
        }
    }, [errorLog?.status]);

    const getToolbarButtons = useCallback(() => () => {
        if (errorLog?.status && canEdit) {
            if (errorLog.status === ExceptionStatus.Error) {
                return (
                    [<Button
                        variant="outlined"
                        color="primary"
                        onClick={onResolveError}
                        key="resolve-error-button"
                    >
                        Resolve Error
                    </Button>]
                );
            } else if (errorLog.status === ExceptionStatus.ResolvedError) {
                return (
                    [<Button
                        variant="outlined"
                        color="primary"
                        onClick={onUnresolveError}
                        key="unresolve-error-button"
                    >
                        Unresolve Error
                    </Button>]
                );
            }
        }
        return undefined;
    }, [errorLog?.status, canEdit]);

    useEffect(() => {
        setDetailsRecordLoaded(!!errorLog);        
        if (errorLog) {
            if (errorLog.status) {
                setDetailsAV(getErrorAV());                
                setDetailsHeader(errorLog.status.replaceAll('_', ' ')); 
                setDetailsToolbarButtons(getToolbarButtons());
            }
            setDetailsPageTitle(errorId);
        }
    }, [errorLog, errorId, error, getErrorAV, getToolbarButtons]);

    useEffect(() => {
        if (updateStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpdateLogStatus());
            // navigate back to the list page
            navigate('/errorlogs');
        } else if (updateStatus?.result === RequestResult.Fail) {
            dispatch(setToastConfig({
                message: updateStatus.message as string,
                severity: ToastSeverity.Error
            }));
            dispatch(captureUpdateLogStatus());
            // navigate back to the list page
            navigate('/errorlogs');
        }
    }, [updateStatus?.result]);

    const onResolveError = () => {
        dispatch(captureUpdateLogStatus());
        if (errorId) {
            dispatch(updateTransactionErrorStatus([errorId], ExceptionStatus.ResolvedError));
        }
    };

    const onUnresolveError = () => {
        dispatch(captureUpdateLogStatus());
        if (errorId) {
            dispatch(updateTransactionErrorStatus([errorId], ExceptionStatus.Error));
        }
    };

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (error: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const onClientNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const onPartnerNameClick = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    // const onDocumentTypeClick = (clientPartnerId: string | undefined) => {
    //     // navigate to the clientPartner to allow the user to view the associated transaction/documentType
    //     if (clientPartnerId) {
    //         let route = "/clientpartner/" + clientPartnerId;
    //         navigate(route);
    //     }
    // };

    const getLogError = () => {
        if (errorLog && errorLog.rawMessage) {
            let parsed = JSON.parse(errorLog.rawMessage);
            if (parsed) {
                let parsedEntries = Object.entries(parsed);
                if (parsedEntries) {
                    return (
                        <TabContainerWithPadding>
                            <Grid container alignItems="flex-start">
                                <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                                    {parsedEntries.map(([key, value], index) => (
                                        <Grid item xs={1} key={`error-property-${index}`}>
                                            <Typography variant='caption' >{key}</Typography>
                                            <TypographyWithReturns variant='body1' >{value as string}</TypographyWithReturns>
                                        </Grid>
                                    ))}
                                </ Grid>
                            </ Grid>
                        </TabContainerWithPadding>
                    );
                }
            }
        }
    };

    const getDocumentTypeDisplay = () => {
        let docType = errorLog?.documentType;
        if (errorLog && docType) {
            // let clientPartnerId = errorLog.clientPartner?.id;
            // if (clientPartnerId) {
            //     return (
            //         <div>
            //             <Link
            //                 component="button"
            //                 variant="body1"
            //                 onClick={() => {
            //                     onDocumentTypeClick(clientPartnerId);
            //                 }}
            //             >
            //                 {docType}
            //             </Link>
            //         </div>
            //     );
                
            // } else {
                return (
                    <Typography variant='body1' >{docType}</Typography>
                )
            //}
        }
    };

    const getLogDetails = () => {
        if (errorLog) {
            let clientName = errorLog.client?.name;
            let partnerName = errorLog.partner?.name;
            let direction = errorLog.transaction?.direction;
            let documentType = errorLog.transaction?.name;
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        {clientName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Client</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onClientNameClick(errorLog.client?.id);
                                        }}
                                    >
                                        {clientName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {partnerName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Partner</Typography>
                                <div>
                                    <Link
                                        component="button"
                                        variant="body1"
                                        onClick={() => {
                                            onPartnerNameClick(errorLog.partner?.id);
                                        }}
                                    >
                                        {partnerName}
                                    </Link>
                                </div>
                            </Grid>
                        )}
                        {errorLog.createdTime && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Time Reported</Typography>
                                <Typography variant='body1' >{getFormattedDateTimeString(errorLog.createdTime)}</Typography>
                            </Grid>
                        )}
                        {direction && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Direction</Typography>
                                <Typography variant='body1' >{direction}</Typography>
                            </Grid>
                        )}
                        {errorLog.processName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Process</Typography>
                                <Typography variant='body1' >{errorLog.processName}</Typography>
                            </Grid>
                        )}
                        {errorLog.description && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Description</Typography>
                                <Typography variant='body1' >{errorLog.description}</Typography>
                            </Grid>
                        )}
                        {errorLog.documentId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Id</Typography>
                                <Typography variant='body1' >{errorLog.documentId}</Typography>
                            </Grid>
                        )}
                        {documentType && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Type</Typography>
                                <Typography variant='body1' >{documentType}</Typography>
                            </Grid>
                        )}
                        {errorLog.documentReferenceId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Document Reference Id</Typography>
                                <Typography variant='body1' >{errorLog.documentReferenceId}</Typography>
                            </Grid>
                        )}
                        {errorLog.originalFileName && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Original File Name</Typography>
                                <Typography variant='body1' >{errorLog.originalFileName}</Typography>
                            </Grid>
                        )}
                        {errorLog.purchaseOrderNumber && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Purchase Order(s)</Typography>
                                <Typography variant='body1' >{errorLog.purchaseOrderNumber}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )
        }
    }

    const getTabbedContainer = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable error tabs"
                    >
                        <TabHeader label="Error" id='vertical-tab-error-details-error' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        {getLogError()}
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );
    }

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={undefined}
            detailsChips={undefined}
            getDetailsListContainer={getLogDetails}
            getTabbedContainer={getTabbedContainer}
            error={error}
            updateAlignment={updateAlignment}
            toolBarButtons={detailsToolbarButtons}
            detailsEditButton={undefined}
            detailsFavoriteButton={undefined}
            getDialogs={()=> undefined}
        />
    );
}

export default ErrorLogDetails;